import { GET_COUNTRIES } from "../types";

const initialState = {
  countries: [],
  loading: false,
  error: "",
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES.REQUEST:
      return {
        ...state,
        countries: [],
        error: "",
        loading: true,
      };

    case GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: action.countries,
        error: "",
        loading: false,
      };

    case GET_COUNTRIES.ERROR:
      return {
        ...state,
        countries: [],
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default countriesReducer;
