import React from "react";
import paylocal from "../assets/images/paylocal.svg"

const Header = () => {
  return (
    <div>
      <header id="header">
        <div className="container">
          <div className="row pt-3">
            <div className="header-column d-flex justify-content-between align-items-center">
              {/* Logo */}
              <div className="logo ">
                <a href="/">

                  <img src={paylocal} width="121px" height="33px" alt="logo" />
                </a>
              </div>

              {/* Login & Signup Link
                    ============================== */}
              <nav className="navbar ">
                <ul className="navbar-nav">
                  <li>
                    <a href>Login</a>{" "}
                  </li>
                  <li className="align-items-center h-auto ms-sm-3">
                    <a
                      className="btn"
                      href="signup.html"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
              </nav>
              {/* Login & Signup Link end */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
