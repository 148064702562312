export const GET_COUNTRIES = {
  REQUEST: "GET_COUNTRIES_REQUEST",
  SUCCESS: "GET_COUNTRIES_SUCCESS",
  ERROR: "GET_COUNTRIES_ERROR",
};

export const GET_CURRENCIES = {
  REQUEST: "GET_CURRENCIES_REQUEST",
  SUCCESS: "GET_CURRENCIES_SUCCESS",
  ERROR: "GET_CURRENCIES_ERROR",
};

export const SEND_MONEY = {
  REQUEST: "SEND_MONEY_REQUEST",
  SUCCESS: "SEND_MONEY_SUCCESS",
  ERROR: "SEND_MONEY_ERROR",
};

export const REQUEST_MONEY = {
  REQUEST: "REQUEST_MONEY_REQUEST",
  SUCCESS: "REQUEST_MONEY_SUCCESS",
  ERROR: "REQUEST_MONEY_ERROR", 
};

export const RESET_MESSAGE= "RESET_MESSAGE";