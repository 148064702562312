import React from 'react';
import { ReactComponent as Vector } from "../assets/images/Vector.svg";
import { ReactComponent as User } from "../assets/images/user.svg";
import { ReactComponent as Bag } from "../assets/images/bag.svg";
import { ReactComponent as Vector1 } from "../assets/images/Vector1.svg";



const WhatYou = () => {
  return (
    <section className="section section4">
      <div className="container">
        <h2 className="text-center mb-5">
          What You Can Do?
        </h2>
        <div className="row mt-4">
          <div className="col-lg-3 col-md-6 mb-5">

            <div className="featured-box">
              <div className="featured-box-icon">
                <Vector width='65px' />
              </div>
              <h3>Send Money</h3>
            </div>

          </div>
          <div className="col-lg-3 col-md-6 mb-5">

            <div className="featured-box ">
              <div className="featured-box-icon ">
                <Vector1 width='65px' />
              </div>
              <h3>Receive Money</h3>
            </div>

          </div>
          <div className="col-lg-3 col-md-6 mb-5">

            <div className="featured-box ">
              <div className="featured-box-icon ">
                <User width='65px' />
              </div>
              <h3>Pay a Friend</h3>
            </div>

          </div>
          <div className="col-lg-3 col-md-6 mb-5">

            <div className="featured-box ">
              <div className="featured-box-icon ">
                <Bag width='65px' />
              </div>
              <h3>Online Shopping</h3>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatYou;