import { combineReducers } from "redux";

import currenciesReducer from "./currencies";
import countriesReducer from "./countries";
import moneyReducer from "./money";

const rootReducer = combineReducers({
  currencies: currenciesReducer,
  countries: countriesReducer,
  money: moneyReducer,
});

export default rootReducer;
