import { SEND_MONEY, RESET_MESSAGE, REQUEST_MONEY } from "../types";

const initialState = {
  message: "",
  loading: false,
  error: "",
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MONEY.REQUEST:
    case REQUEST_MONEY.REQUEST:
      return {
        ...state,
        message: "",
        error: "",
        loading: true,
      };

    case SEND_MONEY.SUCCESS:
    case REQUEST_MONEY.SUCCESS:
      return {
        ...state,
        message: action.message,
        error: "",
        loading: false,
      };

    case SEND_MONEY.ERROR:
    case REQUEST_MONEY.ERROR:
      return {
        ...state,
        message: "",
        error: action.error,
        loading: false,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: "",
        error: "",
        loading: false,
      };

    default:
      return state;
  }
};

export default countriesReducer;
