import React from 'react';
import { ReactComponent as Icon1 } from '../assets/images/like.svg';
import { ReactComponent as Icon2 } from '../assets/images/safe.svg';
import { ReactComponent as Icon3 } from '../assets/images/card.svg';

const WhyUs = () => {
  return (
    <section className="whyUs">
      <div className="container">
        <h2 className="text-center title">
          Why Choose Us?
        </h2>
        <p className="text-center subtitle mb-5">
          Here’s Top 4 reasons to use a Paylocal account to manage your
          money
        </p>
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="featured-box ">
                  <div className="icon">
                    <Icon1 width="65px" />
                  </div>
                  <div className="featured-box-content">
                    <h3 className="font-weight-400">Easy to use</h3>
                    <p>
                      An easy-to-use interface that allows you to quickly
                      send and receive money anywhere in the world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="featured-box ">
                  <div className="icon">
                    <Icon2 width="65px" />
                  </div>
                  <div className="featured-box-content">


                    <h3 className="font-weight-400">Faster Payments</h3>
                    <p>
                      Faster global payment platform that is able to clear
                      payments within seconds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4 ">
                <div className="featured-box ">
                  <div className="icon">

                    <Icon2 width="65px" />
                  </div>
                  <div className="featured-box-content">
                    <h3 className="font-weight-400">Lower Fees</h3>
                    <p>
                      No hidden fees. Flat rate and receivable amount are
                      displayed before you make the transfer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="featured-box style-3">
                  <div className="icon">
                    <Icon3 width="65px" />
                  </div>
                  <div className="featured-box-content">
                    <h3 className="font-weight-400">100% secure</h3>
                    <p>
                      Bank level security and we use 2-factor
                      authentication to protect your account and
                      transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;