import React from 'react';
import HeroImage from "../assets/images/heroImage.gif";

const Hero = () => {
  return (
    <section className='hero'>
      <div className="container">
        <div className="row">

          <div className="col-md-6">
            <img src={HeroImage} alt="heroimage" className='img-fluid' />
          </div>
          <div className="col-md-6 py-5">
            <h1 className='hero-title mb-3'>Global Bank Account For Africans.</h1>
            <p className='hero-description'>Open a foreign currency account for free to receive, send and make payments.<br />
              <span style={{ color: '#00C897' }}>One Account Limitless Possibilities.</span></p>
            <a href="/" className="btn"> Get Started</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;