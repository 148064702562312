import React from 'react';

const Steps = () => {
  return (
    <section className="steps py-5">
      <div className="container py-5">
        <h2 className="text-center title">
          As Simple as 1-2-3
        </h2>
        <div className="row">
          <div className="col-md-4 my-5">
            <div className="featured-box text-center ">
              <div className="text-center">
                <span className="icon">1</span>
              </div>
              <h3 className="mb-3 mt-5">Sign Up Your Account</h3>

            </div>
          </div>
          <div className="col-md-4 my-5">
            <div className="featured-box text-center ">
              <div className="text-center">
                <span className="icon">2</span>
              </div>
              <h3 className="mb-3 mt-5">Send &amp; Receive Money</h3>

            </div>
          </div>
          <div className="col-md-4 my-5 ">
            <div className="featured-box text-center ">
              <div className="text-center">
                <span className="icon">3</span>
              </div>
              <h3 className="mb-3 mt-5">Withdraw Funds</h3>
            </div>
          </div>
        </div>
        <div className="text-center mt-2">
          <a href="#" className="btn">
            Open a Free Account
          </a>
        </div>
      </div>
    </section>
  );
};

export default Steps;