import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./assets/scss/app.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import WhyUs from "./Components/WhyUs";
import Steps from "./Components/Steps";
import WhatYou from "./Components/WhatYou";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Hero />
        <WhyUs />
        <Steps />
        <WhatYou />
        <Footer />
      </BrowserRouter>
      ,
    </div>
  );
}

export default App;
